var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{staticClass:"pa-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"fn24-weight7"},[_vm._v("Loyalty Hub")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('stepForm',{attrs:{"dialog":_vm.dialog,"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"name","show-select":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.class",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.points",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.redeem",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-card',{attrs:{"color":item.color,"width":"40","height":"40","elevation":"0"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"8"}},[_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 'video')?_c('v-chip',{staticClass:"rounded-lg text-center",staticStyle:{"height":"21px"},attrs:{"outlined":""}},[_vm._v("Video")]):_vm._e(),(item.type == 'image')?_c('v-chip',{staticClass:"rounded-lg text-center",staticStyle:{"height":"21px"},attrs:{"outlined":""}},[_vm._v("Image")]):_vm._e(),(item.type == 'pdf')?_c('v-chip',{staticClass:"rounded-lg text-center",staticStyle:{"height":"21px"},attrs:{"outlined":""}},[_vm._v("PDF")]):_vm._e()]}},{key:"item.class",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(item.class))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(item.points))])]}},{key:"item.redeem",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"rounded-lg text-center",staticStyle:{"height":"24px"},attrs:{"outlined":""}},[_vm._v(_vm._s(item.redeem))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"rounded-xl",staticStyle:{"color":"#4f4f4f"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.UpdateLoyalty(item)}}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"rounded-xl ml-2",staticStyle:{"color":"#4f4f4f"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.DeleteLoyalty(item)}}},[_vm._v(" Delete ")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }