<template>
  <v-form>
    <v-row align="center" justify="center" class="pa-4">
      <v-col cols="12">
        <div class="fn24-weight7">Loyalty Hub</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <stepForm :dialog="dialog" :form.sync="form" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :search="search"
            item-key="name"
            show-select
            disable-pagination
            class="elevation-1"
          >
            <template v-slot:header.name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.class="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.points="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.redeem="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-row>
                <v-col cols="4" class="py-0">
                  <v-card
                    :color="item.color"
                    width="40"
                    height="40"
                    elevation="0"
                  />
                </v-col>
                <v-col cols="8" class="py-0">
                  <div style="font-weight: 600">{{ item.name }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                v-if="item.type == 'video'"
                outlined
                class="rounded-lg text-center"
                style="height: 21px"
                >Video</v-chip
              >
              <v-chip
                v-if="item.type == 'image'"
                outlined
                class="rounded-lg text-center"
                style="height: 21px"
                >Image</v-chip
              >
              <v-chip
                v-if="item.type == 'pdf'"
                outlined
                class="rounded-lg text-center"
                style="height: 21px"
                >PDF</v-chip
              >
            </template>
            <template v-slot:[`item.class`]="{ item }">
              <div style="font-weight: 600">{{ item.class }}</div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div style="font-weight: 600">{{ item.status }}</div>
            </template>
            <template v-slot:[`item.points`]="{ item }">
              <div style="font-weight: 600">{{ item.points }}</div>
            </template>
            <template v-slot:[`item.redeem`]="{ item }">
              <v-chip
                outlined
                class="rounded-lg text-center"
                style="height: 24px"
                >{{ item.redeem }}</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-btn
                  @click="UpdateLoyalty(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl"
                  style="color: #4f4f4f"
                >
                  Edit
                </v-btn>
                <v-btn
                  @click="DeleteLoyalty(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl ml-2"
                  style="color: #4f4f4f"
                >
                  Delete
                </v-btn>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Encode, Decode } from "@/services";
import stepForm from "@/components/Shared/stepFormLoyalty";
export default {
  components: {
    stepForm,
  },
  data() {
    return {
      selected: [],
      headers: [
        { text: "", value: "" },
        { text: "Name", value: "name", width: "300" },
        { text: "Class", value: "class" },
        { text: "Status", value: "status" },
        { text: "Points", value: "points" },
        { text: "Redeem", value: "redeem" },
        { text: "Action", value: "action" },
      ],
      items: [
        {
          name: "Discount 10%",
          color: "#072584",
          class: "Silver",
          status: "Enable",
          points: "100",
          redeem: "77",
        },
        {
          name: "Discount 20%",
          color: "#072584",
          class: "Silver",
          status: "Enable",
          points: "200",
          redeem: "30",
        },
        {
          name: "Piggy Stuff",
          color: "#6783D3",
          class: "Gold",
          status: "Enable",
          points: "150",
          redeem: "15",
        },
        {
          name: "Healthy Care",
          color: "#B0BAC9",
          class: "Platinum",
          status: "Enable",
          points: "250",
          redeem: "28",
        },
      ],
      form: {
        name: "",
        date: new Date().toISOString().substr(0, 10),
        url: "",
        picture_1: "",
        menufrom: false,
        publish: false,
      },
      search: "",
      count: 0,
      user: "",
    };
  },
  created() {
    // this.user = JSON.parse(
    //   Decode.decode(localStorage.getItem("userBiAdmin"))
    // );
    // this.getAllcompany();
  },
  methods: {
    async getAllcompany() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllcompany", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateLoyalty(val) {
      localStorage.setItem("Companydata", Encode.encode(val));
      this.$router.push("EditLoyalty");
    },
    ViewLoyalty(val) {
      localStorage.setItem("Companydata", Encode.encode(val));
      this.$router.push("/ViewLoyalty");
    },
    async DeleteLoyalty(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/companiesxx/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllcompany();
        }
      });
    },
    goToCreate() {
      this.$router.push("createcompany");
    },
  },
};
</script>
